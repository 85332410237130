body{
    padding: 0px;
    margin: 0px;
    font-family: 'Montserrat', sans-serif;
    background-color: #e4ebed;
}
.hide-h{
    overflow: hidden;
    height: 100vh;
}
body *{
    font-family: 'Montserrat', sans-serif;
}
header{
    border-bottom: 1px solid #a4b9cc;
    padding: 10px 20px 10px 20px;
}


.logo img{
height: 30px;
margin-top: 5px;
}
.left-menu{
    width: 250px; 
    border-right: 1px solid #a4b9cc;
    height: calc(100vh - 60px);
    flex: 0 0 250px;
    max-width: 250px;
}
.mainspace{

    background-color: rgba(255,255,255,0.45);
    flex: 0 0 calc(100% - 250px);
    max-width: calc(100% - 250px);
}
.content{
    display: flex;
    flex-wrap: wrap;
}
.header-setting{
    text-align: right;
    float: right;
    display: flex;
}
.header-setting>a,
.header-setting>div{
    cursor: pointer;
    text-decoration: none;
    color: black;
    padding: 5px 20px 0px 20px;
    background-color: #ccd7db;
    height: 35px;
    margin-left: 20px;
    border-radius: 20px;
}
.header-setting span{
    position: relative;
    top: -7px;
    margin-right: 6px;
    font-size: 15px;
}
.left-menu__fline{
    list-style: none;
    padding: 0px;

}
.left-menu__fline>li>a{
    padding: 12px 25px 12px 50px;
    color: black;
    text-decoration: none;
    font-size: 15px;
    display: block;
    background-position: left 18px center;
    background-size: 20px;
    background-repeat: no-repeat;

}
.left-menu__fline>li>a:hover,
.left-menu__fline>li>a.active{
    background-color: rgba(0,0,0,0.1);
}

.mainspace{
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}
.mainspace__wrapper{
    padding: 20px 20px;
    min-height: calc(100vh - 96px);
}
footer{
    border-top: 1px solid rgba(0,0,0,0.2);
    padding: 10px;
    font-size: 12px;
    color: rgba(0,0,0,0.3);
}
.lk-block{
    background-color: white;
    box-shadow: 0 0 5px rgba(0,0,0,0.05);
    padding: 15px 25px;
    margin-bottom: 25px;
}
.title{
    margin-top: 5px;
    font-size: 21px;
    font-weight: 500;
}
.lk-block__body{
    background-color: #f5f9f9;
    padding: 15px;
    border: 1px solid #a4b9cc57;
}
.lk-block__body.setting p{
    margin-top: 0px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    font-size: 15px;
}
.lk-block__body.setting p>span{
    color: black;
    font-weight: 500;
    padding-left: 5px;
}
.lk-block__body.setting p:last-child{
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px solid white;
}




.table-wrap {
    display: inline-block;
    width: 100%;
    color: #000;
}
.table-wrap thead {
    text-align: left;
    background-color: rgba(255,255,255,0.5);
}
.table-wrap table { 
    width: 100%;
    margin:0;
    font-size: 14px;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
}
.table-wrap table tr {
    border-bottom: 1px solid #ddd;
    padding: 5px;
}
.table-wrap tbody tr:last-child{
    border-bottom: 0px solid #ddd;
}
.table-wrap table th, 
.table-wrap table td {
    padding: 10px;
}
.table-wrap table th {
    color: black;
    font-size: 14px;
    font-weight: 400;
}
.menu-opener{
    display: none;
    position: absolute;
    width: 35px;
    z-index: 2;
    top: 12px;
    right: 15px;
}
.mob-menu>.left-menu{
    position: fixed;
    top: 0;
    z-index: 9;
    background-color: #e4ebed;
    height: 100vh;
    max-width: 100%;
    left: -100%;
    transition: 0.65s ease;
}
.mob-menu.active>.left-menu{
    left: 0px;
    transition: 0.65s ease;
}






.auth-form{
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;    
    width: 500px;
    padding: 30px 30px;
    max-width: 100%;
    transform: translate(-50%, -50%);
}
.auth-form h2{
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    border-top: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-top: 25px;
    margin-bottom: 25px;
    padding-top: 15px;
    padding-bottom: 20px;
}
.auth-form img{
    height: 35px;
    margin: 0px auto;
    display: block;
}
.auth-form input{
    width: 100%;
    font-size: 17px;
    padding: 10px 15px;
    margin-top: 6px;
    margin-bottom: 20px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
}
.auth-form button{
    background-color: #93c123;
    border: none;
    border-radius: 20px;
    font-size: 17px;
    color: white;
    padding: 10px 40px;
}

.add-btn{
    background-color: #43c5f1;
    width: 100%;
    display: block;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    border: none;
    height: calc(100% - 25px);
    margin-bottom: 25px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    background-image: url('../image/save_icon.svg');
    background-size: 17px;
    background-position: right 15px center;
    background-repeat: no-repeat;
    padding: 10px 50px 10px 10px;
}
.search{
    padding: 10px;
    position: relative;
}
.search input{
    padding: 8px 40px 8px 10px;
    width: 100%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.search button{
    background: none;
    border: none;
    position: absolute;
    right: 0px;
    background-color: white;
    height: 37px;
    width: 45px;
}
.page-title{
    margin-top: 0px;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 500;
}
.item-tabs{
    background:#ecf2f5;
    padding: 10px 10px 0px 10px;
    overflow: hidden;
}
.item-tabs>div{
    min-width: 125px;
    text-align: center;
    padding: 10px 15px;
    border-radius: 10px 10px 0px 0px;
    display: inline-block;
    font-size: 16px;

}
.item-tabs>div.active,
.item-tabs>div:hover{
    box-shadow: 0px 0px 7px rgba(0,0,0,0.15);
    background-color: white;
    cursor: pointer;
}
.item-tab__content{
    padding: 15px 10px;
}
.item-tab__wrapper{
    background-color: #f5f9f9;
    padding: 15px;
    border: 1px solid #a4b9cc57;
}
.item-tab__btns{
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 12px;
}
.item-tab__btns button{
    height: 40px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    padding: 0px 20px;
    color: white;
    margin-right: 15px;
}
.item-tab__props-list>div{ 
    padding: 7px 0px; 
    margin-bottom: 3px;
}
.item-tab__title{ 
    font-weight: bold;
    text-align: right;
    padding-right: 10px;
}
.item-tab__value input,
.item-tab__value select{
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 5px;
}
.item-tab__value{
    line-height: 32px;
}
.grid{
    display: grid;
    min-height: 100%;
    vertical-align: middle;
    align-items: center;
    height: 30px;
}

@media screen and (max-width: 760px) {
    .menu-opener{
        display: block;
    }
    .left-menu{
        display: none;
    }
    .mob-menu>.left-menu{
        display: block;
    }
    .mainspace{
        flex: 0 0 100%;
        max-width: 100%;
    }
    header{
        text-align: center;
    }
    header img{
        margin-bottom: 15px;
    }
    .header-setting{
        display: inline-flex;
        float: none;
    }
    .table-wrap table {
        border: 0;
    }
    .table-wrap table thead {
        display: none;
    }
    .table-wrap table tr {
        margin-bottom: 10px;
        display: block;
        border-bottom: 2px solid #ddd;
    }
    .table-wrap table td {
        display: block;
        text-align: right;
        font-size: 13px;
        border-bottom: 1px dotted #ccc;
        border-right: 1px solid transparent;
    }
    .table-wrap table td:last-child {
        border-bottom: 0;
    }
    .table-wrap table td:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        font-weight: bold;
    }
}



.popup-form,
.popup-setting,
.popup-msg{
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}
.popup-form>div,
.popup-setting>div,
.popup-msg>div{
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 30px 20px;
    width: 400px;
    max-width: 98%;
}
.popup-msg h3{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
.popup-msg .popup-msg__link{
    background-color: #93c123;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    margin: 0px auto;
    display: block;
    width: 200px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    background-color: #93c123;
    border: none;
    border-radius: 20px;
    font-size: 17px;
    color: white;
    padding: 10px 40px;
}
 
 

nav ul{
    text-align: center;
    display: table;
    list-style: none;
    margin: 0px;
}
nav li{
    display: inline-block;
}
nav a{
    margin-right: 30px;
    color: black;
    text-decoration: none;
}
nav a:hover{
    color:#93c123;
}

.developer{
    display: flex;
}
.developer-sidebar{
    width: 350px;
    display: flex;
    flex: 0 0 350px;
    max-width: 100%;
    border-right: 1px solid #a4b9cc;
    padding: 10px 0px;
}
.developer-sidebar ul{
    width: 350px;
    list-style: none;
}
.developer-sidebar>ul{
    margin: 0px;
    padding: 0px;
}
.developer-sidebar *{
    cursor: pointer;
}
.developer-sidebar span{
    padding: 10px 20px;
    display: block;
    font-weight: 500;
    font-size: 16px;
}
.developer-sidebar>div{
    position: relative;
}
.developer-sidebar>div>ul{
    padding-left: 15px;
    padding-right: 15px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: sticky;
    top: 0;
}
.developer-sidebar a{
    text-decoration: none;
    color: black;
}
.developer-sidebar a:hover,
.developer-sidebar .active span,
.developer-sidebar span:hover{
    color:#93c123;
}
.developer-sidebar span svg{
    top: 2px;
    margin-left: 4px;
    position: relative;
}
.developer-sidebar .active path,
.developer-sidebar span:hover path{
    fill:#93c123;
}
.developer-sidebar li ul{
    padding-left: 20px;
    margin-bottom: 5px;
}
.developer-sidebar li li{
    font-size: 14px;
    padding: 7px 20px;
}
.developer-space{
    height: calc(100% - 40px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(255,255,255,0.45);
    flex: 0 0 calc(100% - 400px);
    width: calc(100% - 400px);
    padding: 10px 25px;
}
.developer-space p{
    line-height: 1.5;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
}
.developer-block{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #a4b9cc;
}
.developer-block>h3{
    margin-top: 0px;
}
.developer-block h4{
    font-size: 16px;
    padding-top: 15px;
    margin-bottom: 15px;
}
.developer-block h5{
    font-size: 16px;
    margin-bottom: 10px;
}
.developer-block>ul{
    padding-left: 35px;
    margin-top: 0px;
}
.developer-block>ul li{
    font-size: 15px;
    margin-bottom: 5px;
}
.developer-block__child>ul{
    padding-left: 35px;
    margin-top: 0px;
}
.developer-block__child>ul li{
    font-size: 15px;
    margin-bottom: 5px;
}
.developer-block__child>ul ul{
    margin-top: 5px;
    padding-left: 25px;
    list-style: none;
}
.developer-block__child h4{
    border-top: 1px dashed #a4b9cc;
    font-size: 18px;
}
.codeItem>div{
    border-radius: 10px;
    padding: 10px 10px 10px 0px;
    background-color: rgb(48, 48, 48);
    border: 1px solid #a4b9cc;
}
.maxlength{
    font-size: 14px; 
    font-weight: 500;
}
.codeItem>div *{
    font-weight: normal;
    font-size: 15px;
    line-height: 1.5;
}
.codeItem>div span{
    margin-right: 0px;
}
.codeItem>div span::after{
    display: none;
}
._Chy1W{
    margin-left: 5px;
}
._2bkNM{
    margin: 0px !important;
    margin-left: 30px !important;
}
._Chy1W{
    color: rgb(165, 227, 45) !important;
}
.warning{
    color: rgb(247, 39, 113) !important;
    font-size: 16px;
}
._2bSDX{
    color: rgb(247, 39, 113) !important;
}
._gsbQL{
    color: rgba(255,255,255,0.45) !important;
}
.developer-block__props span{
    color: red;
    font-weight: 500;
}
.url-props{
    font-weight: 500;
    color:rgb(247, 39, 113);
}
.errorkey{
    padding: 15px 20px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.1);
}
.errorkey ul{
    margin: 0px;
    padding: 0px;
    padding-left: 20px;
}
.errorkey p{
    margin-top: 0px;
}

@media(max-width: 760px){
    .developer-sidebar{
        display: none;
    }
    .developer-space{
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }
    .hide-mob,
    nav ul{
        display: none !important;
    }
}

footer a{
    float: right;
    color: rgba(0,0,0,0.5);
}

/* ------------------------------------------------- */
.banner .content{
    display: grid;
    vertical-align: middle;
    align-items: center;
    width: 1200px;
    max-width: 100%;
    margin: 0px auto;
    min-height: calc(100vh - 100px);
    padding: 100px 20px;
}
.banner p{
    font-size: 16px;
    line-height: 1.5;
}
.banner h2{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    padding-bottom: 10px;
}
.banner .slider-wrapper img{
    border-radius: 10px;
    max-width: 100%;
    display: block;
    margin-bottom: -3px;
}

.slider-block{
    position: relative;
    width: 100%;
    height: 100%;
}
.banner .slider-wrapper{
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0px, -50%);
    margin-left: 30px;
}
.banner b{
    color: #93c123;
}
.bannerbtn{
    margin-top: 10px;
    font-size: 16px;
    background-color: black;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 15px 30px;
}
.newmarker{
    display: inline-block;
    text-decoration: none;
    color: black;
    background-color: #e1ffdc;
    padding: 10px 5px;
    padding-right: 45px;
    font-size: 15px;
    border-radius: 30px;
    background-image: url('../image/arrowright.svg');
    background-size: 20px;
    background-position: right 10px center;
    background-repeat: no-repeat;
}
.newmarker span{
    margin-right: 10px;
    border-radius: 30px;
    padding: 5px 20px;
    color: white;
    background-color: #93c123;
}

.popup-form input{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.45);
    padding: 10px 5px;
    margin-top: 4px;
    border-radius: 5px;
}
.popup-form label{
    font-size: 15px;
    margin-bottom: 13px;
    display: block;
}
.popup-form button{
    margin-top: 5px;
    background-color: black;
    border: none;
    color: white;
    font-size: 14px;
    padding: 8px 30px;
    border-radius: 5px;
}
.popup-close{
    text-align: right;
    margin-top: -15px;
    margin-bottom: 5px;
}
.popup-close svg{
    cursor: pointer;
}

@media(max-width: 960px){
    .banner .content{
        overflow: hidden;
    }
    .banner .slider-wrapper{
        margin-left: 0px;
        top: 0;
        transform: translate(0px, 0px);
        margin-top: 0px;
        position: relative;
        margin-bottom: 40px;
    }
    .slider-block{
        max-width: calc(100vw - 40px);
    }
    .banner .content{
        padding: 30px 20px;
    }
}